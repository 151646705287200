@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter-UI-400';
  font-style: normal;
  font-weight: 400;
  src: url('https://liftassets.vfsglobal.com/_angular/assets/styles/css/fonts/Inter-UI-400.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-UI-500';
  font-style: normal;
  font-weight: 500;
  src: url('https://liftassets.vfsglobal.com/_angular/assets/styles/css/fonts/Inter-UI-500.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-UI-700';
  font-style: normal;
  font-weight: 700;
  src: url('https://liftassets.vfsglobal.com/_angular/assets/styles/css/fonts/Inter-UI-700.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-UI-900';
  font-style: normal;
  font-weight: 900;
  src: url('https://liftassets.vfsglobal.com/_angular/assets/styles/css/fonts/Inter-UI-900.woff');
}

body {
  margin: 0;
  font-family: 'Inter-UI-400';
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
