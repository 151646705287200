.successAlert {
  background-color: rgb(242, 249, 244) !important;
  border-left: 4px solid #023020;
  border-radius: 0 !important;
  color: black !important;
  width: 100%;
  height: 100%; 
  display: flex; 
  // align-items: center; 
  // justify-content: center;
  // text-align: center;
  padding: 4px; 
}

  
.errorAlert {
  background-color: rgb(252,247,248) !important;
  border-left: 4px solid #8B0000;
  border-radius: 0 !important;
  color: black !important;
  width: 100%;
  height: 100%; 
  display: flex; 
  padding: 4px;
}

.warningAlert {
  // background-color: rgb(252,247,248) !important;
  // border-left: 4px solid #8B0000;
  border-radius: 0 !important;
  color: black !important;
  width: 100%;
  height: 100%; 
  display: flex; 
  padding: 4px;
}

.infoAlert {
  background-color: rgb(255, 255, 255) !important;
  border-left: 4px solid #dbd3d3;
  border-radius: 0 !important;
  color: rgb(135, 139, 139) !important;
  width: 100%;
  height: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  text-align: center;
  padding: 4px;
}
  